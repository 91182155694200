import React, { useCallback, useState } from 'react'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { InspectionFormEditor } from '@woorcs/inspection-form-editor'
import { constNull, constVoid, pipe } from 'fp-ts/function'
import { useDebouncedCallback } from 'use-debounce'
import * as Ord from 'fp-ts/Ord'
import * as date from 'fp-ts/Date'
import { FormDocument } from '@woorcs/form'
import { useNavigate } from 'react-router'
import { useMutation } from 'urql'
import { FormRevisionStatus } from '@woorcs/graphql/schema'
import { useSnackbar } from '@woorcs/design-system'

import {
  EditFormFormFragment,
  EditFormFormRevisionFragment,
  PublishFormRevisionDocument,
  UpdateFormRevisionDocument
} from './__generated__/EditForm'

type EditFormProps = {
  form: EditFormFormFragment
}

const revisionOrd = pipe(
  Ord.reverse(date.Ord),
  Ord.contramap(
    (revision: EditFormFormRevisionFragment) => new Date(revision.createdAt)
  )
)
const getLatestRevision = (form: EditFormFormFragment) =>
  pipe(form.revisions, A.sort(revisionOrd), A.head)

export const EditForm = ({ form }: EditFormProps) => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const [isPublishing, setPublishing] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [, updateRevision] = useMutation(UpdateFormRevisionDocument)
  const [, publishRevision] = useMutation(PublishFormRevisionDocument)
  const handleChange = useDebouncedCallback((value) => {
    setSaving(true)

    updateRevision({
      input: {
        formId: form.id,
        definition: value,
        title: FormDocument.getDefaultTitle(value),
        editable: true
      }
    }).finally(() => setSaving(false))
  }, 3000)

  const handlePublish = useCallback(
    (formRevisionId: string) => {
      setPublishing(true)
      publishRevision({
        input: { formRevisionId }
      })
        .then(() => {
          showSnackbar({
            title: 'Form has been published',
            variant: 'success'
          })
        })
        .catch((e: Error) => {
          showSnackbar({
            title: e.message,
            variant: 'danger'
          })
        })
        .finally(() => {
          setPublishing(false)
        })
    },
    [publishRevision, showSnackbar]
  )

  const handleQuit = useCallback(() => {
    navigate(`/forms/${form.id}`, {
      replace: true
    })
  }, [form.id, navigate])

  return pipe(
    getLatestRevision(form),
    O.fold(constNull, (revision) => (
      <InspectionFormEditor
        initialValue={revision.definition as any}
        isPublishing={isPublishing}
        isSaving={isSaving}
        // updatedAt={new Date(revision.updatedAt)}
        published={revision.status === FormRevisionStatus.Published}
        onSave={constVoid}
        onPublish={() => handlePublish(revision.id)}
        onChange={handleChange.callback}
        onQuit={handleQuit}
      />
    ))
  )
}
