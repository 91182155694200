import { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { constNull, pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as Optional from 'monocle-ts/Optional'
import {
  TabPanel,
  Tabs,
  Box,
  PlusIcon,
  PrimaryButton
} from '@woorcs/design-system'
import {
  AddLanguageMenu,
  EditorProvider,
  LanguageList,
  useEditor
} from '@woorcs/inspection-form-editor'
import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { useDebouncedCallback } from 'use-debounce'
import { FormDocument } from '@woorcs/form'
import { queryToDatumEither } from '@woorcs/graphql'
import { useMutation, useQuery } from 'urql'

import {
  FormRevisionList,
  BackButton,
  ListPage,
  ListPageContent,
  PageContent,
  ResourceNotFound,
  SubmissionList,
  useSubmissionFilters
} from '@app/components'
import { FiltersProvider } from '@app/components/filters'

import { FormDetailsPageHeader } from './Header'
import {
  FormDetailsPageFormFragment,
  FormDetailsPageQuery,
  FormDetailsPageQueryDocument,
  UpdateFormDocument
} from './__generated__/FormDetails'

const FormNotFound = () => {
  return (
    <ResourceNotFound
      title='Form not found'
      description="We could not find the form you're looking for."
    >
      <BackButton to='/forms' variant='filled'>
        Back to forms
      </BackButton>
    </ResourceNotFound>
  )
}

const formResponseOptional = pipe(
  Optional.id<FormDetailsPageQuery>(),
  Optional.prop('form'),
  Optional.fromNullable
)

const FormSubmissions = ({ formId }: { formId: string }) => {
  const filtersContext = useSubmissionFilters({
    formId
  })

  return (
    <FiltersProvider value={filtersContext}>
      <SubmissionList />
    </FiltersProvider>
  )
}

interface LanguagesTabEditorProps {
  definition: InspectionFormDefinition.InspectionFormDefinition
  children: ReactElement
  onChange(definition: InspectionFormDefinition.InspectionFormDefinition): void
}

const LanguagesTabEditor = ({
  definition,
  children,
  onChange
}: LanguagesTabEditorProps) => {
  const editor = useEditor(definition, onChange, false)

  return <EditorProvider editor={editor}>{children}</EditorProvider>
}

interface LanguagesTabProps {
  form: FormDetailsPageFormFragment
}

export const LanguagesTab = ({ form }: LanguagesTabProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const revision = O.some(form.latestRevision)
  const [, updateRevision] = useMutation(UpdateFormDocument)
  const handleChange = useDebouncedCallback((value) => {
    setIsSaving(true)
    updateRevision({
      input: {
        formId: form.id,
        definition: value,
        title: FormDocument.getDefaultTitle(value),
        editable: true
      }
    }).finally(() => setIsSaving(false))
  })

  return pipe(
    revision,
    O.fold(constNull, (revision) => (
      <LanguagesTabEditor
        definition={revision.definition}
        onChange={handleChange.callback}
      >
        <Box pt={4}>
          <Box mb={6}>
            <AddLanguageMenu>
              <PrimaryButton leftIcon={<PlusIcon />}>
                Add language
              </PrimaryButton>
            </AddLanguageMenu>
          </Box>

          <LanguageList isSaving={isSaving} />
        </Box>
      </LanguagesTabEditor>
    ))
  )
}

export const FormDetailsPage = () => {
  const { formId } = useParams()

  return pipe(
    useQuery({
      query: FormDetailsPageQueryDocument,
      variables: { id: formId as string }
    }),
    queryToDatumEither(formResponseOptional),
    DE.squash(
      (loading) => {
        if (!loading) {
          return <FormNotFound />
        }

        return null
      },
      // TODO: this should be an error view
      () => <FormNotFound />,
      (form) => (
        <Tabs>
          <ListPage>
            <FormDetailsPageHeader form={form} />
            <ListPageContent px={0}>
              <TabPanel
                display='flex'
                flexDirection='column'
                height='100%'
                flexGrow={1}
              >
                <FormSubmissions formId={formId as string} />
              </TabPanel>

              <TabPanel>
                <FormRevisionList revisions={form.revisions} />
              </TabPanel>

              <TabPanel>
                <PageContent>
                  <LanguagesTab form={form} />
                </PageContent>
              </TabPanel>
            </ListPageContent>
          </ListPage>
        </Tabs>
      )
    )
  )
}
