import { ReactElement } from 'react'
import { Modal, ModalDialog, ModalDisclosure } from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'

import { EditDocument } from './EditDocument'

type EditDocumentModalProps = {
  document: FormDocument.FormDocument
  children: ReactElement
}

export const EditDocumentModal = ({
  document,
  children
}: EditDocumentModalProps) => (
  <Modal>
    <ModalDisclosure>{children}</ModalDisclosure>
    <ModalDialog width={540} px={5} py={8}>
      <EditDocument document={document} />
    </ModalDialog>
  </Modal>
)
