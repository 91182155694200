import {
  Input,
  Modal,
  ModalBody,
  ModalDialog,
  ModalDisclosure,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  SecondaryButton,
  useSnackbar
} from '@woorcs/design-system'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from 'urql'
import * as t from 'io-ts'
import { Email, getFormikValidator } from '@woorcs/utils/src/formik'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FormField, SubmitButton } from '../Formik'

import { ShareSubmissionPdfMutationDocument } from './__generated__/ShareSubmissionPdfModal'

const shareSubmissionPdfFormCodec = t.type({
  email: Email
})

const initialValues = {
  email: '' as Email
}

type ShareSubmissionPdfFormValue = t.TypeOf<typeof shareSubmissionPdfFormCodec>

const validator = getFormikValidator(shareSubmissionPdfFormCodec)

interface ShareSubmissionPdfModalProps {
  submissionRevisionId: string
  children: ReactElement
}

export const ShareSubmissionPdfModal = ({
  submissionRevisionId,
  children
}: ShareSubmissionPdfModalProps) => {
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbar()
  const [, shareSubmissionPdfMutation] = useMutation(
    ShareSubmissionPdfMutationDocument
  )

  const handleSubmit = useCallback(
    (
      value: ShareSubmissionPdfFormValue,
      helpers: FormikHelpers<ShareSubmissionPdfFormValue>
    ) => {
      shareSubmissionPdfMutation({
        input: {
          submissionRevisionId,
          email: value.email
        }
      })
        .then(() => {
          helpers.resetForm({})

          showSnackbar({
            title: 'Submission sent',
            variant: 'success'
          })
        })
        .catch(() => {
          showSnackbar({
            title: 'Failed to share submission',
            variant: 'danger'
          })
        })
        .finally(() => helpers.setSubmitting(false))
    },
    [shareSubmissionPdfMutation, showSnackbar, submissionRevisionId]
  )

  return (
    <Modal>
      <ModalDisclosure>{children}</ModalDisclosure>

      <ModalDialog aria-label='Search'>
        <ModalHeader>
          <ModalTitle>Share Submission</ModalTitle>
        </ModalHeader>

        <Formik
          initialValues={initialValues}
          validate={validator}
          onSubmit={handleSubmit}
        >
          <Form>
            <ModalBody>
              <FormField
                name='email'
                infoMessage='A pdf version of the submission will be sent to the email address.'
                showMessages
              >
                <Input type='email' placeholder='Email' />
              </FormField>
            </ModalBody>

            <ModalFooter>
              <ModalDismiss render={<SecondaryButton mr={2} />}>
                {t('common:cancel')}
              </ModalDismiss>
              <SubmitButton label={t('common:send')} />
            </ModalFooter>
          </Form>
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
