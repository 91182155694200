import {
  Box,
  H4,
  Text,
  Subtitle,
  Separator,
  PrimaryButton,
  DialogSidebarContent,
  SecondaryButton,
  Flex
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useQuery } from 'urql'
import { useParams } from 'react-router'
import { Location } from 'history'
import { useOrganization } from '@woorcs/graphql'

import { pdfUrl } from '@app/utils/router'

import { SubmissionRevisionList } from '../SubmissionRevisionList'
import { SidebarRoute } from '../SidebarRoute'
import { ShareSubmissionPdfModal } from '../ShareSubmissionPdfModal'

import { SubmissionDetailsSidebarQueryDocument } from './__generated__/SubmissionDetailsSidebar'

type SubmissionDetailsSidebarProps = {
  prevLocation: Location
}

export const SubmissionDetailsSidebar = ({
  prevLocation
}: SubmissionDetailsSidebarProps) => {
  const { submissionId } = useParams()
  const organization = useOrganization()

  const { t } = useTranslation()
  const [query] = useQuery({
    query: SubmissionDetailsSidebarQueryDocument,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      submissionId: submissionId!
    }
  })
  const submission = query.data?.submission

  if (!submission) {
    return null
  }

  return (
    <SidebarRoute prevLocation={prevLocation}>
      <DialogSidebarContent aria-label='Submission'>
        <Box py={12}>
          <Box px={8}>
            <H4 mb={6}>
              {t('submissions:submissionDetailsSidebar.title')} #{submission.id}
            </H4>

            <Box mb={6}>
              <Box>
                <Text fontSize='small' mr={6}>
                  <Text fontWeight='bold' mr={1}>
                    {t('submissions:submissionDetailsSidebar.submittedBy')}:
                  </Text>
                  <Text>{submission.user.name}</Text>
                </Text>

                <Text fontSize='small'>
                  <Text fontWeight='bold' mr={1}>
                    {t('common:date')}:
                  </Text>
                  <Text>
                    {format(
                      new Date(submission.current.createdAt),
                      'yyyy-MM-dd'
                    )}
                  </Text>
                </Text>
              </Box>
            </Box>
          </Box>

          <Separator mb={4} />

          <Flex flexDirection='column' mb={4} px={8} gridRowGap={2}>
            <PrimaryButton
              as='a'
              width='100%'
              {...({
                href: pdfUrl(organization.id, submission.current.id),
                target: '_blank'
              } as any)}
            >
              {t('common:download')}
            </PrimaryButton>

            <ShareSubmissionPdfModal
              submissionRevisionId={submission.current.id}
            >
              <SecondaryButton width='100%'>Share submission</SecondaryButton>
            </ShareSubmissionPdfModal>
          </Flex>

          <Separator mb={8} />

          <Box px={8}>
            <Subtitle mb={4}>{t('common:history')}</Subtitle>

            <SubmissionRevisionList
              submissionId={submission.id}
              revisions={submission?.revisions}
            />
          </Box>
        </Box>
      </DialogSidebarContent>
    </SidebarRoute>
  )
}
