/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  JSON: any
}

export type Account = {
  __typename: 'Account'
  email: Scalars['String']
  id: Scalars['ID']
  superuser: Scalars['Boolean']
  users: Array<User>
}

export type AddUserToOrganizationInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  role: Maybe<Role>
  tags: Array<Scalars['ID']>
}

export type AddUserToOrganizationPayload =
  | AddUserToOrganizationSuccess
  | UserAlreadyAddedError

export type AddUserToOrganizationSuccess = {
  __typename: 'AddUserToOrganizationSuccess'
  user: User
}

export type CreateFormInput = {
  definition: Maybe<Scalars['JSON']>
  editable: Maybe<Scalars['Boolean']>
  title: Scalars['String']
}

export type CreateFormPayload = CreateFormSuccess

export type CreateFormSuccess = {
  __typename: 'CreateFormSuccess'
  form: Form
}

export type CreateSubmissionInput = {
  formId: Scalars['ID']
  submissionData: Scalars['JSON']
}

export type CreateSubmissionPayload =
  | CreateSubmissionSuccess
  | SubmissionNotFoundError

export type CreateSubmissionRevisionInput = {
  submissionId: Scalars['ID']
}

export type CreateSubmissionRevisionPayload =
  | CreateSubmissionRevisionSuccess
  | SubmissionNotFoundError

export type CreateSubmissionRevisionSuccess = {
  __typename: 'CreateSubmissionRevisionSuccess'
  submission: Submission
}

export type CreateSubmissionSuccess = {
  __typename: 'CreateSubmissionSuccess'
  submission: Submission
}

export type CreateTagInput = {
  color: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type CreateTagPayload = CreateTagSuccess | TagExistsError

export type CreateTagSuccess = {
  __typename: 'CreateTagSuccess'
  tag: Tag
}

export type DeleteFormError = Error & {
  __typename: 'DeleteFormError'
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type DeleteFormInput = {
  formId: Scalars['ID']
}

export type DeleteFormPayload = DeleteFormError | DeleteFormSuccess

export type DeleteFormRevisionError = Error & {
  __typename: 'DeleteFormRevisionError'
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type DeleteFormRevisionInput = {
  formRevisionId: Scalars['ID']
}

export type DeleteFormRevisionPayload =
  | DeleteFormRevisionError
  | DeleteFormRevisionSuccess

export type DeleteFormRevisionSuccess = {
  __typename: 'DeleteFormRevisionSuccess'
  success: Scalars['Boolean']
}

export type DeleteFormSuccess = {
  __typename: 'DeleteFormSuccess'
  success: Scalars['Boolean']
}

export type DeleteSubmissionError = Error & {
  __typename: 'DeleteSubmissionError'
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type DeleteSubmissionInput = {
  submissionId: Scalars['ID']
}

export type DeleteSubmissionPayload =
  | DeleteSubmissionError
  | DeleteSubmissionSuccess

export type DeleteSubmissionRevisionError = Error & {
  __typename: 'DeleteSubmissionRevisionError'
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type DeleteSubmissionRevisionInput = {
  submissionRevisionId: Scalars['ID']
}

export type DeleteSubmissionRevisionPayload =
  | DeleteSubmissionRevisionError
  | DeleteSubmissionRevisionSuccess

export type DeleteSubmissionRevisionSuccess = {
  __typename: 'DeleteSubmissionRevisionSuccess'
  success: Scalars['Boolean']
}

export type DeleteSubmissionSuccess = {
  __typename: 'DeleteSubmissionSuccess'
  success: Scalars['Boolean']
}

export type DeleteTagInput = {
  id: Scalars['ID']
}

export type DeleteTagPayload = DeleteTagSuccess

export type DeleteTagSuccess = {
  __typename: 'DeleteTagSuccess'
  success: Scalars['Boolean']
}

export type DisableUserInput = {
  userId: Scalars['ID']
}

export type DisableUserPayload = DisableUserSuccess

export type DisableUserSuccess = {
  __typename: 'DisableUserSuccess'
  user: User
}

export type EnableUserPayload = EnableUserSuccess

export type EnableUserSuccess = {
  __typename: 'EnableUserSuccess'
  user: User
}

export type Error = {
  message: Scalars['String']
}

export type Form = {
  __typename: 'Form'
  active: Maybe<FormRevision>
  createdAt: Scalars['Date']
  editable: Scalars['Boolean']
  id: Scalars['ID']
  latestRevision: FormRevision
  revisions: Array<FormRevision>
  title: Scalars['String']
}

export type FormRevision = {
  __typename: 'FormRevision'
  createdAt: Scalars['Date']
  definition: Scalars['JSON']
  id: Scalars['ID']
  parent: Form
  publishedAt: Maybe<Scalars['Date']>
  status: FormRevisionStatus
  submissions: Array<Submission>
  version: Scalars['Int']
}

export enum FormRevisionStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type Mutation = {
  __typename: 'Mutation'
  addUserToOrganization: AddUserToOrganizationPayload
  createForm: CreateFormPayload
  createSubmission: CreateSubmissionPayload
  createSubmissionRevision: CreateSubmissionRevisionPayload
  createTag: CreateTagPayload
  deleteForm: DeleteFormPayload
  deleteFormRevision: DeleteFormRevisionPayload
  deleteSubmission: DeleteSubmissionPayload
  deleteSubmissionRevision: DeleteSubmissionRevisionPayload
  deleteTag: DeleteTagPayload
  disableUser: DisableUserPayload
  enableUser: EnableUserPayload
  publishFormRevision: PublishFormRevisionPayload
  publishSubmissionRevision: PublishSubmissionRevisionPayload
  removeUsersFromOrganization: RemoveUsersPayload
  requestCsvExport: Maybe<Scalars['Boolean']>
  requestLogoUploadUrl: RequestUploadUrlPayload
  requestSubmissionUploadUrl: RequestUploadUrlPayload
  shareSubmissionPdf: ShareSubmissionPdfPayload
  storeSubmissionPdf: StoreSubmissionPdfPayload
  updateForm: UpdateFormPayload
  updateLogo: UpdateLogoPayload
  updateSubmissionRevision: UpdateSubmissionRevisionPayload
  updateTag: UpdateTagPayload
  updateUser: UpdateUserPayload
  updateViewer: UpdateViewerPayload
}

export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput
}

export type MutationCreateFormArgs = {
  input: CreateFormInput
}

export type MutationCreateSubmissionArgs = {
  input: CreateSubmissionInput
}

export type MutationCreateSubmissionRevisionArgs = {
  input: CreateSubmissionRevisionInput
}

export type MutationCreateTagArgs = {
  input: CreateTagInput
}

export type MutationDeleteFormArgs = {
  input: DeleteFormInput
}

export type MutationDeleteFormRevisionArgs = {
  input: DeleteFormRevisionInput
}

export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput
}

export type MutationDeleteSubmissionRevisionArgs = {
  input: DeleteSubmissionRevisionInput
}

export type MutationDeleteTagArgs = {
  input: DeleteTagInput
}

export type MutationDisableUserArgs = {
  input: DisableUserInput
}

export type MutationEnableUserArgs = {
  input: DisableUserInput
}

export type MutationPublishFormRevisionArgs = {
  input: PublishFormRevisionInput
}

export type MutationPublishSubmissionRevisionArgs = {
  input: PublishSubmissionRevisionInput
}

export type MutationRemoveUsersFromOrganizationArgs = {
  input: RemoveUsersFromOrganizationInput
}

export type MutationRequestCsvExportArgs = {
  input: SubmissionFilter
}

export type MutationRequestLogoUploadUrlArgs = {
  input: RequestUploadUrlInput
}

export type MutationRequestSubmissionUploadUrlArgs = {
  input: RequestUploadUrlInput
}

export type MutationShareSubmissionPdfArgs = {
  input: ShareSubmissionPdfInput
}

export type MutationStoreSubmissionPdfArgs = {
  id: Scalars['ID']
}

export type MutationUpdateFormArgs = {
  input: UpdateFormInput
}

export type MutationUpdateLogoArgs = {
  input: UpdateLogoInput
}

export type MutationUpdateSubmissionRevisionArgs = {
  input: UpdateSubmissionRevisionInput
}

export type MutationUpdateTagArgs = {
  input: UpdateTagInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput
}

export type Organization = {
  __typename: 'Organization'
  createdAt: Scalars['Date']
  forms: Array<Form>
  id: Scalars['ID']
  logo: Maybe<Scalars['String']>
  name: Scalars['String']
  tags: Array<Tag>
}

export type PageInfo = {
  __typename: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  limit: Scalars['Int']
  offset: Scalars['Int']
  totalCount: Scalars['Int']
}

export type PublishFormRevisionInput = {
  formRevisionId: Scalars['ID']
}

export type PublishFormRevisionPayload = PublishFormRevisionSuccess

export type PublishFormRevisionSuccess = {
  __typename: 'PublishFormRevisionSuccess'
  form: Form
}

export type PublishSubmissionRevisionInput = {
  submissionRevisionId: Scalars['ID']
}

export type PublishSubmissionRevisionPayload =
  | PublishSubmissionRevisionSuccess
  | SubmissionRevisionAlreadyPublished
  | SubmissionRevisionInvalid
  | SubmissionRevisionNotFoundError

export type PublishSubmissionRevisionSuccess = {
  __typename: 'PublishSubmissionRevisionSuccess'
  submission: Submission
}

export type Query = {
  __typename: 'Query'
  form: Form
  formRevision: FormRevision
  forms: Array<Form>
  organization: Organization
  searchUsers: Array<User>
  submission: Submission
  submissions: SubmissionListPage
  tag: Maybe<Tag>
  user: Maybe<User>
  users: UserListPage
  viewer: User
}

export type QueryFormArgs = {
  id: Scalars['ID']
}

export type QueryFormRevisionArgs = {
  id: Scalars['ID']
}

export type QuerySearchUsersArgs = {
  limit?: Maybe<Scalars['Int']>
  query: Maybe<Scalars['String']>
}

export type QuerySubmissionArgs = {
  id: Scalars['ID']
}

export type QuerySubmissionsArgs = {
  creatorId: Maybe<Scalars['ID']>
  formId: Maybe<Scalars['ID']>
  from: Maybe<Scalars['Date']>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
  tags?: Maybe<Array<Scalars['ID']>>
  to: Maybe<Scalars['Date']>
}

export type QueryTagArgs = {
  id: Scalars['ID']
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
}

export type RemoveSelfError = Error & {
  __typename: 'RemoveSelfError'
  message: Scalars['String']
}

export type RemoveUsersFromOrganizationInput = {
  userIds: Array<Scalars['ID']>
}

export type RemoveUsersPayload = RemoveSelfError | RemoveUsersSuccess

export type RemoveUsersSuccess = {
  __typename: 'RemoveUsersSuccess'
  success: Scalars['Boolean']
}

export type RequestUploadUrlInput = {
  extension: Scalars['String']
}

export type RequestUploadUrlPayload =
  | RequestUploadUrlSuccess
  | StorageNotDefined

export type RequestUploadUrlSuccess = {
  __typename: 'RequestUploadUrlSuccess'
  filename: Scalars['String']
  publicUrl: Scalars['String']
  url: Scalars['String']
}

export enum Role {
  Admin = 'ADMIN'
}

export type ShareSubmissionPdfInput = {
  email: Scalars['String']
  submissionRevisionId: Scalars['ID']
}

export type ShareSubmissionPdfPayload = ShareSubmissionPdfSuccess

export type ShareSubmissionPdfSuccess = {
  __typename: 'ShareSubmissionPdfSuccess'
  success: Scalars['Boolean']
}

export type StorageNotDefined = {
  __typename: 'StorageNotDefined'
  message: Scalars['String']
}

export type StoreSubmissionPdfPayload = StoreSubmissionPdfSuccess

export type StoreSubmissionPdfSuccess = {
  __typename: 'StoreSubmissionPdfSuccess'
  pdfPath: Scalars['String']
}

export type Submission = {
  __typename: 'Submission'
  current: SubmissionRevision
  formRevision: FormRevision
  id: Scalars['ID']
  revisions: Array<SubmissionRevision>
  user: User
}

export type SubmissionFilter = {
  creatorId: Maybe<Scalars['ID']>
  formId: Scalars['ID']
  from: Maybe<Scalars['Date']>
  tags: Maybe<Array<Scalars['ID']>>
  to: Maybe<Scalars['Date']>
}

export type SubmissionListPage = {
  __typename: 'SubmissionListPage'
  data: Array<Submission>
  pageInfo: PageInfo
}

export type SubmissionNotFoundError = Error & {
  __typename: 'SubmissionNotFoundError'
  message: Scalars['String']
}

export type SubmissionRevision = {
  __typename: 'SubmissionRevision'
  createdAt: Scalars['Date']
  id: Scalars['ID']
  parent: Submission
  publishedAt: Maybe<Scalars['Date']>
  status: SubmissionRevisionStatus
  submissionData: Scalars['JSON']
  version: Scalars['Int']
}

export type SubmissionRevisionAlreadyPublished = Error & {
  __typename: 'SubmissionRevisionAlreadyPublished'
  message: Scalars['String']
}

export type SubmissionRevisionInvalid = Error & {
  __typename: 'SubmissionRevisionInvalid'
  message: Scalars['String']
}

export type SubmissionRevisionNotFoundError = Error & {
  __typename: 'SubmissionRevisionNotFoundError'
  message: Scalars['String']
}

export enum SubmissionRevisionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type SubmissionsCsv = {
  __typename: 'SubmissionsCsv'
  csvPath: Scalars['String']
}

export type Tag = {
  __typename: 'Tag'
  color: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  userCount: Scalars['Int']
}

export type TagExistsError = Error & {
  __typename: 'TagExistsError'
  message: Scalars['String']
}

export type UpdateFormInput = {
  definition: Scalars['JSON']
  editable: Scalars['Boolean']
  formId: Scalars['ID']
  title: Scalars['String']
}

export type UpdateFormPayload = UpdateFormSuccess

export type UpdateFormSuccess = {
  __typename: 'UpdateFormSuccess'
  form: Form
}

export type UpdateLogoInput = {
  filename: Scalars['String']
}

export type UpdateLogoPayload = UpdateLogoSuccess

export type UpdateLogoSuccess = {
  __typename: 'UpdateLogoSuccess'
  organization: Organization
}

export type UpdatePublishedSubmissionRevisionError = Error & {
  __typename: 'UpdatePublishedSubmissionRevisionError'
  message: Scalars['String']
}

export type UpdateSubmissionRevisionInput = {
  submissionData: Scalars['JSON']
  submissionRevisionId: Scalars['ID']
}

export type UpdateSubmissionRevisionPayload =
  | SubmissionRevisionNotFoundError
  | UpdatePublishedSubmissionRevisionError
  | UpdateSubmissionRevisionSuccess

export type UpdateSubmissionRevisionSuccess = {
  __typename: 'UpdateSubmissionRevisionSuccess'
  submission: Submission
}

export type UpdateTagInput = {
  color: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateTagPayload = UpdateTagSuccess

export type UpdateTagSuccess = {
  __typename: 'UpdateTagSuccess'
  tag: Tag
}

export type UpdateUserInput = {
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  role: Maybe<Role>
  tags: Array<Scalars['ID']>
}

export type UpdateUserPayload = UpdateUserSuccess

export type UpdateUserSuccess = {
  __typename: 'UpdateUserSuccess'
  user: User
}

export type UpdateViewerEmailFailedError = Error & {
  __typename: 'UpdateViewerEmailFailedError'
  message: Scalars['String']
}

export type UpdateViewerInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type UpdateViewerPayload = UpdateViewerSuccess

export type UpdateViewerSuccess = {
  __typename: 'UpdateViewerSuccess'
  viewer: User
}

export type User = {
  __typename: 'User'
  account: Account
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  name: Scalars['String']
  organization: Organization
  role: Maybe<Role>
  status: UserStatus
  submissionDrafts: Array<Submission>
  submissions: Array<Submission>
  tags: Array<Tag>
}

export type UserAlreadyAddedError = Error & {
  __typename: 'UserAlreadyAddedError'
  message: Scalars['String']
}

export type UserError = {
  message: Scalars['String']
  path: Scalars['String']
}

export type UserListPage = {
  __typename: 'UserListPage'
  data: Array<User>
  pageInfo: PageInfo
}

export enum UserStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Pending = 'PENDING',
  Removed = 'REMOVED'
}

interface AnyType {
  __typename: string
}

export const matchAddUserToOrganizationPayload =
  <A>(patterns: {
    [K in AddUserToOrganizationPayload['__typename']]: (
      t: Extract<AddUserToOrganizationPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<AddUserToOrganizationPayload, '__typename'> &
      Partial<Omit<AddUserToOrganizationPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchCreateFormPayload =
  <A>(patterns: {
    [K in CreateFormPayload['__typename']]: (
      t: Extract<CreateFormPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<CreateFormPayload, '__typename'> &
      Partial<Omit<CreateFormPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchCreateSubmissionPayload =
  <A>(patterns: {
    [K in CreateSubmissionPayload['__typename']]: (
      t: Extract<CreateSubmissionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<CreateSubmissionPayload, '__typename'> &
      Partial<Omit<CreateSubmissionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchCreateSubmissionRevisionPayload =
  <A>(patterns: {
    [K in CreateSubmissionRevisionPayload['__typename']]: (
      t: Extract<CreateSubmissionRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<CreateSubmissionRevisionPayload, '__typename'> &
      Partial<Omit<CreateSubmissionRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchCreateTagPayload =
  <A>(patterns: {
    [K in CreateTagPayload['__typename']]: (
      t: Extract<CreateTagPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<CreateTagPayload, '__typename'> &
      Partial<Omit<CreateTagPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDeleteFormPayload =
  <A>(patterns: {
    [K in DeleteFormPayload['__typename']]: (
      t: Extract<DeleteFormPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DeleteFormPayload, '__typename'> &
      Partial<Omit<DeleteFormPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDeleteFormRevisionPayload =
  <A>(patterns: {
    [K in DeleteFormRevisionPayload['__typename']]: (
      t: Extract<DeleteFormRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DeleteFormRevisionPayload, '__typename'> &
      Partial<Omit<DeleteFormRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDeleteSubmissionPayload =
  <A>(patterns: {
    [K in DeleteSubmissionPayload['__typename']]: (
      t: Extract<DeleteSubmissionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DeleteSubmissionPayload, '__typename'> &
      Partial<Omit<DeleteSubmissionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDeleteSubmissionRevisionPayload =
  <A>(patterns: {
    [K in DeleteSubmissionRevisionPayload['__typename']]: (
      t: Extract<DeleteSubmissionRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DeleteSubmissionRevisionPayload, '__typename'> &
      Partial<Omit<DeleteSubmissionRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDeleteTagPayload =
  <A>(patterns: {
    [K in DeleteTagPayload['__typename']]: (
      t: Extract<DeleteTagPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DeleteTagPayload, '__typename'> &
      Partial<Omit<DeleteTagPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchDisableUserPayload =
  <A>(patterns: {
    [K in DisableUserPayload['__typename']]: (
      t: Extract<DisableUserPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<DisableUserPayload, '__typename'> &
      Partial<Omit<DisableUserPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchEnableUserPayload =
  <A>(patterns: {
    [K in EnableUserPayload['__typename']]: (
      t: Extract<EnableUserPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<EnableUserPayload, '__typename'> &
      Partial<Omit<EnableUserPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchPublishFormRevisionPayload =
  <A>(patterns: {
    [K in PublishFormRevisionPayload['__typename']]: (
      t: Extract<PublishFormRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<PublishFormRevisionPayload, '__typename'> &
      Partial<Omit<PublishFormRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchPublishSubmissionRevisionPayload =
  <A>(patterns: {
    [K in PublishSubmissionRevisionPayload['__typename']]: (
      t: Extract<PublishSubmissionRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<PublishSubmissionRevisionPayload, '__typename'> &
      Partial<Omit<PublishSubmissionRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchRemoveUsersPayload =
  <A>(patterns: {
    [K in RemoveUsersPayload['__typename']]: (
      t: Extract<RemoveUsersPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<RemoveUsersPayload, '__typename'> &
      Partial<Omit<RemoveUsersPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchRequestUploadUrlPayload =
  <A>(patterns: {
    [K in RequestUploadUrlPayload['__typename']]: (
      t: Extract<RequestUploadUrlPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<RequestUploadUrlPayload, '__typename'> &
      Partial<Omit<RequestUploadUrlPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchShareSubmissionPdfPayload =
  <A>(patterns: {
    [K in ShareSubmissionPdfPayload['__typename']]: (
      t: Extract<ShareSubmissionPdfPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<ShareSubmissionPdfPayload, '__typename'> &
      Partial<Omit<ShareSubmissionPdfPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchStoreSubmissionPdfPayload =
  <A>(patterns: {
    [K in StoreSubmissionPdfPayload['__typename']]: (
      t: Extract<StoreSubmissionPdfPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<StoreSubmissionPdfPayload, '__typename'> &
      Partial<Omit<StoreSubmissionPdfPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateFormPayload =
  <A>(patterns: {
    [K in UpdateFormPayload['__typename']]: (
      t: Extract<UpdateFormPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateFormPayload, '__typename'> &
      Partial<Omit<UpdateFormPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateLogoPayload =
  <A>(patterns: {
    [K in UpdateLogoPayload['__typename']]: (
      t: Extract<UpdateLogoPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateLogoPayload, '__typename'> &
      Partial<Omit<UpdateLogoPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateSubmissionRevisionPayload =
  <A>(patterns: {
    [K in UpdateSubmissionRevisionPayload['__typename']]: (
      t: Extract<UpdateSubmissionRevisionPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateSubmissionRevisionPayload, '__typename'> &
      Partial<Omit<UpdateSubmissionRevisionPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateTagPayload =
  <A>(patterns: {
    [K in UpdateTagPayload['__typename']]: (
      t: Extract<UpdateTagPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateTagPayload, '__typename'> &
      Partial<Omit<UpdateTagPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateUserPayload =
  <A>(patterns: {
    [K in UpdateUserPayload['__typename']]: (
      t: Extract<UpdateUserPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateUserPayload, '__typename'> &
      Partial<Omit<UpdateUserPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const matchUpdateViewerPayload =
  <A>(patterns: {
    [K in UpdateViewerPayload['__typename']]: (
      t: Extract<UpdateViewerPayload, { __typename: K }>
    ) => A
  }) =>
  (
    f: Pick<UpdateViewerPayload, '__typename'> &
      Partial<Omit<UpdateViewerPayload, '__typename'>>
  ) =>
    patterns[f.__typename](f as any)

export const addUserToOrganizationSuccess = (
  t: Omit<AddUserToOrganizationSuccess, '__typename'>
): AddUserToOrganizationSuccess => ({
  __typename: 'AddUserToOrganizationSuccess',
  ...t
})

export const userAlreadyAddedError = (
  t: Omit<UserAlreadyAddedError, '__typename'>
): UserAlreadyAddedError => ({
  __typename: 'UserAlreadyAddedError',
  ...t
})

export const createFormSuccess = (
  t: Omit<CreateFormSuccess, '__typename'>
): CreateFormSuccess => ({
  __typename: 'CreateFormSuccess',
  ...t
})

export const createSubmissionSuccess = (
  t: Omit<CreateSubmissionSuccess, '__typename'>
): CreateSubmissionSuccess => ({
  __typename: 'CreateSubmissionSuccess',
  ...t
})

export const submissionNotFoundError = (
  t: Omit<SubmissionNotFoundError, '__typename'>
): SubmissionNotFoundError => ({
  __typename: 'SubmissionNotFoundError',
  ...t
})

export const createSubmissionRevisionSuccess = (
  t: Omit<CreateSubmissionRevisionSuccess, '__typename'>
): CreateSubmissionRevisionSuccess => ({
  __typename: 'CreateSubmissionRevisionSuccess',
  ...t
})

export const createTagSuccess = (
  t: Omit<CreateTagSuccess, '__typename'>
): CreateTagSuccess => ({
  __typename: 'CreateTagSuccess',
  ...t
})

export const tagExistsError = (
  t: Omit<TagExistsError, '__typename'>
): TagExistsError => ({
  __typename: 'TagExistsError',
  ...t
})

export const deleteFormError = (
  t: Omit<DeleteFormError, '__typename'>
): DeleteFormError => ({
  __typename: 'DeleteFormError',
  ...t
})

export const deleteFormSuccess = (
  t: Omit<DeleteFormSuccess, '__typename'>
): DeleteFormSuccess => ({
  __typename: 'DeleteFormSuccess',
  ...t
})

export const deleteFormRevisionError = (
  t: Omit<DeleteFormRevisionError, '__typename'>
): DeleteFormRevisionError => ({
  __typename: 'DeleteFormRevisionError',
  ...t
})

export const deleteFormRevisionSuccess = (
  t: Omit<DeleteFormRevisionSuccess, '__typename'>
): DeleteFormRevisionSuccess => ({
  __typename: 'DeleteFormRevisionSuccess',
  ...t
})

export const deleteSubmissionError = (
  t: Omit<DeleteSubmissionError, '__typename'>
): DeleteSubmissionError => ({
  __typename: 'DeleteSubmissionError',
  ...t
})

export const deleteSubmissionSuccess = (
  t: Omit<DeleteSubmissionSuccess, '__typename'>
): DeleteSubmissionSuccess => ({
  __typename: 'DeleteSubmissionSuccess',
  ...t
})

export const deleteSubmissionRevisionError = (
  t: Omit<DeleteSubmissionRevisionError, '__typename'>
): DeleteSubmissionRevisionError => ({
  __typename: 'DeleteSubmissionRevisionError',
  ...t
})

export const deleteSubmissionRevisionSuccess = (
  t: Omit<DeleteSubmissionRevisionSuccess, '__typename'>
): DeleteSubmissionRevisionSuccess => ({
  __typename: 'DeleteSubmissionRevisionSuccess',
  ...t
})

export const deleteTagSuccess = (
  t: Omit<DeleteTagSuccess, '__typename'>
): DeleteTagSuccess => ({
  __typename: 'DeleteTagSuccess',
  ...t
})

export const disableUserSuccess = (
  t: Omit<DisableUserSuccess, '__typename'>
): DisableUserSuccess => ({
  __typename: 'DisableUserSuccess',
  ...t
})

export const enableUserSuccess = (
  t: Omit<EnableUserSuccess, '__typename'>
): EnableUserSuccess => ({
  __typename: 'EnableUserSuccess',
  ...t
})

export const publishFormRevisionSuccess = (
  t: Omit<PublishFormRevisionSuccess, '__typename'>
): PublishFormRevisionSuccess => ({
  __typename: 'PublishFormRevisionSuccess',
  ...t
})

export const publishSubmissionRevisionSuccess = (
  t: Omit<PublishSubmissionRevisionSuccess, '__typename'>
): PublishSubmissionRevisionSuccess => ({
  __typename: 'PublishSubmissionRevisionSuccess',
  ...t
})

export const submissionRevisionAlreadyPublished = (
  t: Omit<SubmissionRevisionAlreadyPublished, '__typename'>
): SubmissionRevisionAlreadyPublished => ({
  __typename: 'SubmissionRevisionAlreadyPublished',
  ...t
})

export const submissionRevisionInvalid = (
  t: Omit<SubmissionRevisionInvalid, '__typename'>
): SubmissionRevisionInvalid => ({
  __typename: 'SubmissionRevisionInvalid',
  ...t
})

export const submissionRevisionNotFoundError = (
  t: Omit<SubmissionRevisionNotFoundError, '__typename'>
): SubmissionRevisionNotFoundError => ({
  __typename: 'SubmissionRevisionNotFoundError',
  ...t
})

export const removeSelfError = (
  t: Omit<RemoveSelfError, '__typename'>
): RemoveSelfError => ({
  __typename: 'RemoveSelfError',
  ...t
})

export const removeUsersSuccess = (
  t: Omit<RemoveUsersSuccess, '__typename'>
): RemoveUsersSuccess => ({
  __typename: 'RemoveUsersSuccess',
  ...t
})

export const requestUploadUrlSuccess = (
  t: Omit<RequestUploadUrlSuccess, '__typename'>
): RequestUploadUrlSuccess => ({
  __typename: 'RequestUploadUrlSuccess',
  ...t
})

export const storageNotDefined = (
  t: Omit<StorageNotDefined, '__typename'>
): StorageNotDefined => ({
  __typename: 'StorageNotDefined',
  ...t
})

export const shareSubmissionPdfSuccess = (
  t: Omit<ShareSubmissionPdfSuccess, '__typename'>
): ShareSubmissionPdfSuccess => ({
  __typename: 'ShareSubmissionPdfSuccess',
  ...t
})

export const storeSubmissionPdfSuccess = (
  t: Omit<StoreSubmissionPdfSuccess, '__typename'>
): StoreSubmissionPdfSuccess => ({
  __typename: 'StoreSubmissionPdfSuccess',
  ...t
})

export const updateFormSuccess = (
  t: Omit<UpdateFormSuccess, '__typename'>
): UpdateFormSuccess => ({
  __typename: 'UpdateFormSuccess',
  ...t
})

export const updateLogoSuccess = (
  t: Omit<UpdateLogoSuccess, '__typename'>
): UpdateLogoSuccess => ({
  __typename: 'UpdateLogoSuccess',
  ...t
})

export const updatePublishedSubmissionRevisionError = (
  t: Omit<UpdatePublishedSubmissionRevisionError, '__typename'>
): UpdatePublishedSubmissionRevisionError => ({
  __typename: 'UpdatePublishedSubmissionRevisionError',
  ...t
})

export const updateSubmissionRevisionSuccess = (
  t: Omit<UpdateSubmissionRevisionSuccess, '__typename'>
): UpdateSubmissionRevisionSuccess => ({
  __typename: 'UpdateSubmissionRevisionSuccess',
  ...t
})

export const updateTagSuccess = (
  t: Omit<UpdateTagSuccess, '__typename'>
): UpdateTagSuccess => ({
  __typename: 'UpdateTagSuccess',
  ...t
})

export const updateUserSuccess = (
  t: Omit<UpdateUserSuccess, '__typename'>
): UpdateUserSuccess => ({
  __typename: 'UpdateUserSuccess',
  ...t
})

export const updateViewerSuccess = (
  t: Omit<UpdateViewerSuccess, '__typename'>
): UpdateViewerSuccess => ({
  __typename: 'UpdateViewerSuccess',
  ...t
})

export const isAddUserToOrganizationSuccess = (
  a: AnyType
): a is AddUserToOrganizationSuccess =>
  a.__typename === 'AddUserToOrganizationSuccess'

export const isUserAlreadyAddedError = (
  a: AnyType
): a is UserAlreadyAddedError => a.__typename === 'UserAlreadyAddedError'

export const isCreateFormSuccess = (a: AnyType): a is CreateFormSuccess =>
  a.__typename === 'CreateFormSuccess'

export const isCreateSubmissionSuccess = (
  a: AnyType
): a is CreateSubmissionSuccess => a.__typename === 'CreateSubmissionSuccess'

export const isSubmissionNotFoundError = (
  a: AnyType
): a is SubmissionNotFoundError => a.__typename === 'SubmissionNotFoundError'

export const isCreateSubmissionRevisionSuccess = (
  a: AnyType
): a is CreateSubmissionRevisionSuccess =>
  a.__typename === 'CreateSubmissionRevisionSuccess'

export const isCreateTagSuccess = (a: AnyType): a is CreateTagSuccess =>
  a.__typename === 'CreateTagSuccess'

export const isTagExistsError = (a: AnyType): a is TagExistsError =>
  a.__typename === 'TagExistsError'

export const isDeleteFormError = (a: AnyType): a is DeleteFormError =>
  a.__typename === 'DeleteFormError'

export const isDeleteFormSuccess = (a: AnyType): a is DeleteFormSuccess =>
  a.__typename === 'DeleteFormSuccess'

export const isDeleteFormRevisionError = (
  a: AnyType
): a is DeleteFormRevisionError => a.__typename === 'DeleteFormRevisionError'

export const isDeleteFormRevisionSuccess = (
  a: AnyType
): a is DeleteFormRevisionSuccess =>
  a.__typename === 'DeleteFormRevisionSuccess'

export const isDeleteSubmissionError = (
  a: AnyType
): a is DeleteSubmissionError => a.__typename === 'DeleteSubmissionError'

export const isDeleteSubmissionSuccess = (
  a: AnyType
): a is DeleteSubmissionSuccess => a.__typename === 'DeleteSubmissionSuccess'

export const isDeleteSubmissionRevisionError = (
  a: AnyType
): a is DeleteSubmissionRevisionError =>
  a.__typename === 'DeleteSubmissionRevisionError'

export const isDeleteSubmissionRevisionSuccess = (
  a: AnyType
): a is DeleteSubmissionRevisionSuccess =>
  a.__typename === 'DeleteSubmissionRevisionSuccess'

export const isDeleteTagSuccess = (a: AnyType): a is DeleteTagSuccess =>
  a.__typename === 'DeleteTagSuccess'

export const isDisableUserSuccess = (a: AnyType): a is DisableUserSuccess =>
  a.__typename === 'DisableUserSuccess'

export const isEnableUserSuccess = (a: AnyType): a is EnableUserSuccess =>
  a.__typename === 'EnableUserSuccess'

export const isPublishFormRevisionSuccess = (
  a: AnyType
): a is PublishFormRevisionSuccess =>
  a.__typename === 'PublishFormRevisionSuccess'

export const isPublishSubmissionRevisionSuccess = (
  a: AnyType
): a is PublishSubmissionRevisionSuccess =>
  a.__typename === 'PublishSubmissionRevisionSuccess'

export const isSubmissionRevisionAlreadyPublished = (
  a: AnyType
): a is SubmissionRevisionAlreadyPublished =>
  a.__typename === 'SubmissionRevisionAlreadyPublished'

export const isSubmissionRevisionInvalid = (
  a: AnyType
): a is SubmissionRevisionInvalid =>
  a.__typename === 'SubmissionRevisionInvalid'

export const isSubmissionRevisionNotFoundError = (
  a: AnyType
): a is SubmissionRevisionNotFoundError =>
  a.__typename === 'SubmissionRevisionNotFoundError'

export const isRemoveSelfError = (a: AnyType): a is RemoveSelfError =>
  a.__typename === 'RemoveSelfError'

export const isRemoveUsersSuccess = (a: AnyType): a is RemoveUsersSuccess =>
  a.__typename === 'RemoveUsersSuccess'

export const isRequestUploadUrlSuccess = (
  a: AnyType
): a is RequestUploadUrlSuccess => a.__typename === 'RequestUploadUrlSuccess'

export const isStorageNotDefined = (a: AnyType): a is StorageNotDefined =>
  a.__typename === 'StorageNotDefined'

export const isShareSubmissionPdfSuccess = (
  a: AnyType
): a is ShareSubmissionPdfSuccess =>
  a.__typename === 'ShareSubmissionPdfSuccess'

export const isStoreSubmissionPdfSuccess = (
  a: AnyType
): a is StoreSubmissionPdfSuccess =>
  a.__typename === 'StoreSubmissionPdfSuccess'

export const isUpdateFormSuccess = (a: AnyType): a is UpdateFormSuccess =>
  a.__typename === 'UpdateFormSuccess'

export const isUpdateLogoSuccess = (a: AnyType): a is UpdateLogoSuccess =>
  a.__typename === 'UpdateLogoSuccess'

export const isUpdatePublishedSubmissionRevisionError = (
  a: AnyType
): a is UpdatePublishedSubmissionRevisionError =>
  a.__typename === 'UpdatePublishedSubmissionRevisionError'

export const isUpdateSubmissionRevisionSuccess = (
  a: AnyType
): a is UpdateSubmissionRevisionSuccess =>
  a.__typename === 'UpdateSubmissionRevisionSuccess'

export const isUpdateTagSuccess = (a: AnyType): a is UpdateTagSuccess =>
  a.__typename === 'UpdateTagSuccess'

export const isUpdateUserSuccess = (a: AnyType): a is UpdateUserSuccess =>
  a.__typename === 'UpdateUserSuccess'

export const isUpdateViewerSuccess = (a: AnyType): a is UpdateViewerSuccess =>
  a.__typename === 'UpdateViewerSuccess'
