import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Location, useLocation } from 'react-router'
import * as O from 'fp-ts/Option'
import { constant, constNull, pipe } from 'fp-ts/function'

import {
  FormDetailsPage,
  FormListPage,
  DashboardPage,
  AcceptOrganizationInvitePage,
  FormRevisionEditorPage,
  SubmissionsPage,
  UsersPage,
  NotFoundPage
} from '@app/pages'
import { useAuth } from '@app/auth'
import { SettingsModal } from '@app/settings'

import { AppLayout } from './components'
import { SubmissionDetailsSidebar } from './components/SubmissionDetailsSidebar'

const Login = () => {
  const [{ isAuthenticated }, { loginWithRedirect }] = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        prompt: 'login',
        fragment: location.pathname
      })
    }
  })

  return null
}

export const PublicRoutes = () => (
  <Routes>
    <Route
      path='/invitation/:email'
      element={<AcceptOrganizationInvitePage />}
    />
    <Route path='*' element={<Login />} />
  </Routes>
)

interface FormRoutesProps {
  location: Location
}

export const FormRoutes = ({ location }: FormRoutesProps) => {
  return (
    <Routes location={location}>
      <Route element={<FormListPage />} index />
      <Route path=':formId' element={<FormDetailsPage />} />
    </Routes>
  )
}

interface SubmissionsRoutesProps {
  location: Location
}

export const SubmissionsRoutes = ({ location }: SubmissionsRoutesProps) => (
  <Routes location={location}>
    <Route path='*' element={<SubmissionsPage />} />
    {/* <Route path='*' element={<NotFoundPage />} /> */}
  </Routes>
)

interface ModalLocationState {
  backgroundLocation: Location
}

export const AuthenticatedRoutes = () => {
  const location = useLocation() as Location<ModalLocationState>
  const backgroundLocation = pipe(
    location.state?.backgroundLocation,
    O.fromNullable
  )

  return (
    <>
      {pipe(
        backgroundLocation,
        O.getOrElseW(constant(location)),
        (location) => (
          <Routes location={location}>
            <Route
              path='/forms/:formId/edit'
              element={<FormRevisionEditorPage />}
            />

            <Route path='*' element={<AppLayout />}>
              <Route element={<DashboardPage />} index />
              <Route
                path='forms/*'
                element={<FormRoutes location={location} />}
              />
              <Route
                path='submissions/*'
                element={<SubmissionsRoutes location={location} />}
              />
              <Route path='users/*' element={<UsersPage />} />
              <Route path='*' element={<NotFoundPage />} />
            </Route>
          </Routes>
        )
      )}

      {pipe(
        backgroundLocation,
        O.fold(constNull, (backgroundLocation) => (
          <Routes>
            <Route
              path='settings/*'
              element={<SettingsModal prevLocation={backgroundLocation} />}
            />
            <Route
              path='submissions/:submissionId'
              element={
                <SubmissionDetailsSidebar prevLocation={backgroundLocation} />
              }
            />
          </Routes>
        ))
      )}
    </>
  )
}
